window.o_nav = window.o_nav || {};
window.o_nav.sulu = window.o_nav.sulu || {};

export function send(type, ...args) {
    if (window.o_global && window.o_global.eventQBus && typeof window.o_global.eventQBus.emit === 'function') {
        if (window.o_global.debug.status().activated) {
            console.log(type, " submitting ", args);

            window.o_nav.sulu.trackingDebugLog = window.o_nav.sulu.trackingDebugLog || [];
            window.o_nav.sulu.trackingDebugLog.push({type: type, args: args});
        }
        window.o_global.eventQBus.emit(type, ...args);
    }
}

export const types = {
    tsSend: "tracking.bct.sendEventToTrackingServer",
    moveAction: "tracking.bct.submitMoveAction",
    action: "tracking.bct.submitAction",
    viewTracking: 'tracking.bct.addFeaturesToPageImpression',
};
